import Image from 'next/image';

import { RichTextEditor, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const isContainsObject = (arr) => arr.some((item) => typeof item === 'object');

const InnerProductSummary = (value) => {
  if (isContainsObject(value)) {
    return (
      <div>
        {value.map(
          ({ renderType = null, value: displayValue, displayName }, index) => {
            if (renderType === 'Rich Text') {
              return (
                <RichTextEditor
                  key={index}
                  {...{
                    readOnly: true,
                    values: displayValue
                  }}
                />
              );
            }
            return (
              <div key={index}>
                {displayName} : {displayValue}
              </div>
            );
          }
        )}
      </div>
    );
  }
  return value.join(', ');
};

const ProductDetail = ({ displayAttributes }) => (
  <div className='product-detail'>
    <Text
      {...{
        content: 'Product Details',
        className: 'text-nero text-base md:text-xl font-semibold'
      }}
    />
    <div className='flex flex-wrap flex-col md:flex-row gap-4 md:gap-0 justify-between mt-4'>
      {displayAttributes.map(({ groupName, value, renderType }, index) => (
        <div
          key={index}
          className='flex md:gap-1 w-full md:w-1/2 md:pr-6'
        >
          <div className='md:mt-1 w-6 h-6'>
            <Image
              width={16}
              height={16}
              src={`${staticMediaStoreBaseURL}/icons/star-icon.svg`}
              alt='attribute'
            />
          </div>
          <div className='flex flex-col gap-1 flex-1 text-xs md:text-sm font-light text-dim-gray md:mt-1'>
            <Text
              {...{
                content: groupName,
                className: 'text-sm font-medium text-nero'
              }}
            />
            {renderType === 'Rich Text' && (
              <RichTextEditor
                {...{
                  readOnly: true,
                  values: value
                }}
              />
            )}
            {renderType === 'simple' && (
              <div>
                {Array.isArray(value) ? InnerProductSummary(value) : value}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default ProductDetail;
