import PropTypes from 'prop-types';
import { useState } from 'react';

import Text from '@/atoms/common/Text';

const TabMenu = ({ tabs, tabsClass, enableShowTab, showtab, className }) => (
  <ul className={` overflow-x-auto ${className}`}>
    {tabs.map((tab) => (
      <li
        key={tab.id}
        className={`${
          tab.tabsClass || ' float-left'
        } ${tabsClass} tab-menu leading-7 ${
          tab.id === showtab
            ? ' text-brand border-bottom-brand '
            : ' text-nero border-bottom-nero'
        }`}
        onClick={() => enableShowTab(tab.id, tab.title)}
      >
        <a
          href={tab.href}
          key={tab.title}
          id={tab.id}
          className='px-4 md:px-0 cursor-pointer inline-block py-2'
        >
          <Text content={tab.title} />
        </a>
      </li>
    ))}
  </ul>
);

const TabContent = ({ tabs, showtab, tabContainerStyle }) =>
  tabs.map(
    (tab) =>
      showtab === tab.id && (
        <div
          key={tab.id}
          className={`${tabContainerStyle}  fade-in-text`}
        >
          {tab.content}
        </div>
      )
  );

const Tabs = ({
  tabContainerStyle = '',
  tabsClass = '',
  tabs = '',
  className = ''
}) => {
  const [showtab, setShowtab] = useState(tabs[0].id);
  const enableShowTab = (id) => {
    setShowtab(id);
  };

  return (
    <div>
      <TabMenu
        {...{
          tabs,
          tabsClass,
          enableShowTab,
          showtab,
          className
        }}
      />
      <div>
        <TabContent {...{ tabs, showtab, tabContainerStyle }} />
      </div>
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  className: PropTypes.string
};
