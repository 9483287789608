import Image from 'next/image';
import { useRouter } from 'next/router';
import propTypes from 'prop-types';

import { Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const statusColor = {
  Draft: 'bg-gray text-dim-gray',
  Collaborate: 'text-green-base bg-greenlight'
};

const statusLabel = {
  Draft: 'Draft',
  Collaborate: 'Collaborate'
};

const onSelectCartCard = ({
  cartName,
  id,
  setShowCartSelectorModal,
  updateSelectedCart,
  userEventId,
  isDefault
}) => {
  updateSelectedCart({ id, cartName, userEventId, isDefault });
  setShowCartSelectorModal(false);
};

const CartStatus = ({ status }) => (
  <div className='flex gap-4'>
    <div
      className={`flex px-4 font-medium py-1 ${statusColor[status]} text-xs rounded-full self-center`}
    >
      {statusLabel[status]}
    </div>
  </div>
);

const CartInfoSummary = ({ cartName }) => (
  <Text
    content={cartName}
    className='text-nero text-base font-medium truncate max-w-50'
  />
);

const CartDetails = ({ cartName, status, isSelected }) => (
  <div className='flex flex-col flex-1 px-2 py-4 gap-2 relative'>
    <div className='self-end absolute top-1'>
      <Image
        src={`${staticMediaStoreBaseURL}/icons/${
          isSelected ? 'tick-red.svg' : 'tick-gray.svg'
        }`}
        width={16}
        height={16}
        alt='tick'
        layout='fixed'
      />
    </div>
    <CartInfoSummary {...{ cartName }} />
    <div className='flex justify-between'>
      <CartStatus {...{ status }}></CartStatus>
    </div>
  </div>
);

const CartNumber = ({ cartNumber, isSelected }) => (
  <Text
    content={cartNumber}
    className={` ${
      isSelected ? 'text-white' : 'text-dim-gray'
    } text-sm font-medium `}
  />
);

const CartNumberSection = ({ cartNumber, isSelected }) => (
  <div
    className={` flex justify-center rounded-l-lg text-center ${
      isSelected ? 'bg-border' : 'bg-platinum'
    } flex-col w-20 gap-1 `}
  >
    <div>
      <Image
        src={`${staticMediaStoreBaseURL}/icons/${
          isSelected ? 'cart-white.svg' : 'cart-dimgray.svg'
        }`}
        width={28}
        height={28}
        alt='cart'
        layout='fixed'
      />
    </div>
    <CartNumber {...{ cartNumber, isSelected }} />
  </div>
);

const CartListCard = ({
  cartName,
  cartNumber,
  id,
  isDefault,
  isSelected,
  resetSelectedCart,
  setShowCartSelectorModal,
  status,
  updateSelectedCart,
  userEventId
}) => {
  const Router = useRouter();
  return (
    <div
      onClick={() =>
        onSelectCartCard({
          cartName,
          id,
          setShowCartSelectorModal,
          updateSelectedCart,
          userEventId,
          isDefault
        })
      }
      className={`rounded-lg border ${
        isSelected ? 'border-brand' : 'border-platinum'
      } cursor-pointer w-full md:w-80 hover:border-brand`}
    >
      <a
        onClick={() => {
          const cartDetailUrl = `/host-carts/${id}`;
          const isDummyCart = id === '';
          const cartNewUrl = isDummyCart
            ? '/empty-default-cart'
            : cartDetailUrl;
          isDummyCart && resetSelectedCart();
          Router.push(cartNewUrl);
        }}
      >
        <div className='flex w-full'>
          <CartNumberSection {...{ cartNumber, isSelected }} />
          <CartDetails {...{ cartName, status, isSelected }} />
        </div>
      </a>
    </div>
  );
};

export default CartListCard;

CartListCard.propTypes = {
  cartName: propTypes.string,
  cartNumber: propTypes.number,
  id: propTypes.string,
  isSelected: propTypes.bool,
  resetSelectedCart: propTypes.func,
  role: propTypes.string,
  setShowCartSelectorModal: propTypes.func,
  status: propTypes.string,
  updateSelectedCart: propTypes.func
};
