import Image from 'next/image';
import { useState } from 'react';

import { ImageCarouselModal } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getImageURL } from '@/helpers/carousel';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const CoverImage = ({ coverImage, setCarouselModalImage }) => {
  const [isMobile] = useIsMobile();

  return (
    <a onClick={() => setCarouselModalImage(coverImage)}>
      <div className='absolute top-2 left-1 z-10'>
        <Image
          {...{
            alt: 'view all images',
            className: 'cursor-pointer',
            height: 20,
            layout: 'fixed',
            priority: true,
            src: `${staticMediaStoreBaseURL}/icons/resize-icon.svg`,
            width: 20
          }}
        />
      </div>
      <div className='w-15 md:w-24 border-1'>
        <Image
          {...{
            alt: 'product-image',
            className: 'rounded-lg',
            height: isMobile ? 59 : 74,
            src: getImageURL(coverImage),
            width: isMobile ? 94 : 122
          }}
        />
      </div>
    </a>
  );
};

const LineItemMedia = ({
  itemMediaForCarousel: itemMediaForCarouselRaw = []
}) => {
  const [carouselModalImage, setCarouselModalImage] = useState(null);

  const itemMediaForCarousel = itemMediaForCarouselRaw.map((image) => ({
    ...image,
    imageTitle: ''
  }));

  if (itemMediaForCarousel.length === 0) {
    itemMediaForCarousel.push({
      url: '/default-images/default-product-image.svg',
      sortOrder: 1
    });
  }

  return (
    <div className='relative'>
      <CoverImage
        {...{
          coverImage: [itemMediaForCarousel],
          setCarouselModalImage
        }}
      />

      {carouselModalImage && (
        <ImageCarouselModal
          {...{
            images: itemMediaForCarousel,
            modalImage: carouselModalImage,
            openImageModal: setCarouselModalImage
          }}
        />
      )}
    </div>
  );
};

export default LineItemMedia;
