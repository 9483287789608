import Image from 'next/image';

import { Button, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getImageURL } from '@/helpers/carousel';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { convertToStandardDateFormat } from '@/lib/time';
import { PAYMENT_SOURCE_TYPE } from '@/services/payment.service';

const NoDueOrderMessage = () => (
  <div className='text-white text-center text-sm font-medium mt-5'>
    <Text
      {...{
        content: '🎉 Hurray!! You are all set.'
      }}
    />
    <Text
      {...{
        content: 'There is no due amount for this order.'
      }}
    />
  </div>
);

const EventInfo = ({ icon, label }) => (
  <div className='flex gap-4'>
    <Image
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      width={40}
      height={40}
      alt='cart'
    />
    <Text
      {...{
        content: label,
        className: 'text-sm md:text-base font-medium self-center'
      }}
    />
  </div>
);

const EventMedia = ({ eventImage }) => (
  <div className='rounded-lg'>
    <Image
      src={getImageURL(eventImage)}
      width={115}
      height={115}
      alt='event media'
      className='rounded-lg'
    />
  </div>
);

const EventTitle = ({ eventName }) => (
  <Text
    {...{
      content: eventName,
      className: 'self-center text-xl font-medium'
    }}
  />
);

const OrderTotal = ({ orderTotal }) => (
  <div className='flex justify-between'>
    <div className='flex gap-1'>
      <Text
        {...{
          content: 'Grand Total',
          className: 'text-sm md:text-base font-medium'
        }}
      />
      <Text
        {...{
          content: '(AED)',
          className: 'text-sm font-light leading-5 md:leading-6'
        }}
      />
    </div>
    <Text
      {...{
        content: orderTotal,
        className: 'text-sm md:text-base font-medium'
      }}
    />
  </div>
);

const PriceBreakUp = ({ label, price, sublabel }) => (
  <div className='flex justify-between'>
    <div className='flex flex-col'>
      <div className='flex gap-1'>
        <Text
          {...{
            content: label,
            className: 'text-sm md:text-base font-medium'
          }}
        />
        <Text
          {...{
            content: '(AED)',
            className: 'text-sm font-light leading-5 md:leading-6'
          }}
        />
      </div>
      <Text
        {...{
          content: sublabel,
          className: 'text-sm font-light'
        }}
      />
    </div>
    <Text
      {...{
        content: price,
        className: 'text-sm md:text-base font-medium'
      }}
    />
  </div>
);

const EventDetails = ({
  eventContactName,
  eventDate,
  eventImage,
  eventName,
  hostCreditEntity,
  itemNumber,
  paymentSourceType
}) => {
  const iconUrls = {
    calendar: 'calendar-red-with-frame.svg',
    cart: 'cart-red-with-frame.svg',
    hostCreditEntity: 'host-credit-entity-red-icon.svg',
    order: 'order-number-red.svg',
    user: 'user-red-with-frame.svg'
  };

  const eventInfo = [
    { icon: iconUrls[paymentSourceType], label: itemNumber },
    { icon: iconUrls.user, label: eventContactName },
    {
      icon: iconUrls.hostCreditEntity,
      label: hostCreditEntity?.name,
      show: Boolean(hostCreditEntity?.name)
    },
    { icon: iconUrls.calendar, label: convertToStandardDateFormat(eventDate) }
  ];

  return (
    <div className='flex flex-col md:w-1/2 gap-2 md:gap-4 rounded-t-2xl md:rounded-tr-none md:rounded-l-2xl p-4 md:p-8 bg-white'>
      <div className='flex gap-4'>
        <EventMedia {...{ eventImage }} />
        <EventTitle {...{ eventName }} />
      </div>
      {eventInfo
        .filter(({ show = true }) => show)
        .map(({ icon, label }, index) => (
          <EventInfo
            key={index}
            {...{ icon, label }}
          />
        ))}
    </div>
  );
};

const PaymentDetails = ({
  amountDue,
  amountPaid,
  duePriceBreakUpLabel,
  loginAndViewDetailsCTALabel,
  onClickLoginAndViewDetails,
  onClickPayAmount,
  orderTotal,
  payAmountCTALabel,
  paymentSourceType,
  paymentPriceBreakUpLabel
}) => {
  const showNoDuePaymentMessage = amountDue <= 0;
  const isPaymentForOrder = paymentSourceType === PAYMENT_SOURCE_TYPE.ORDER;
  return (
    <div className='md:w-1/2 bg-planner-cart-bg rounded-b-2xl md:rounded-bl-none md:rounded-r-2xl p-4 md:p-8 md:h-112'>
      <Text
        {...{
          content: 'Payment Details',
          className: 'text-base md:text-xl text-white font-medium mb-4'
        }}
      />
      <div className='border border-white text-white rounded-xl p-4'>
        <div className='flex flex-col gap-4'>
          <OrderTotal
            {...{ orderTotal: parseFormatPriceValueFromAPI(orderTotal) }}
          />
          <div className='border-t'></div>
          <PriceBreakUp
            {...{
              label: paymentPriceBreakUpLabel,
              sublabel: 'Advance payment',
              price: parseFormatPriceValueFromAPI(amountPaid)
            }}
          />
          {isPaymentForOrder && (
            <PriceBreakUp
              {...{
                label: duePriceBreakUpLabel,
                sublabel: 'Due before Event date',
                price: parseFormatPriceValueFromAPI(amountDue)
              }}
            />
          )}
        </div>
      </div>
      {showNoDuePaymentMessage ? (
        <NoDueOrderMessage />
      ) : (
        <Button
          {...{
            children: payAmountCTALabel,
            className:
              'text-brand text-sm font-medium bg-white mt-6 py-4 rounded-lg',
            onClick: onClickPayAmount
          }}
        />
      )}
      <Button
        {...{
          children: loginAndViewDetailsCTALabel,
          className:
            'text-white text-sm font-medium border border-white py-3 my-4 rounded-lg',
          onClick: onClickLoginAndViewDetails
        }}
      />
    </div>
  );
};

const PublicPaymentModal = ({
  amountDue,
  amountPaid,
  checkoutEvent: { iconUrl: eventImage, name: eventName },
  duePriceBreakUpLabel,
  eventDetails: { eventContactName, eventDate },
  itemNumber,
  loginAndViewDetailsCTALabel,
  onClickLoginAndViewDetails,
  onClickPayAmount,
  orderTotal,
  payAmountCTALabel,
  paymentPriceBreakUpLabel,
  paymentSourceType,
  user: { hostCreditEntity }
}) => (
  <div className='flex h-screen bg-payment-modal payment-modal-bg-image-web'>
    <div className='md:block hidden payment-modal-overlayer'></div>
    <div
      className={`relative z-10 w-full md:w-56.25 bg-white bg-payment-modal-mobile payment-modal-bg-image-mobile rounded-2xl mx-auto md:mt-32 md:h-112`}
    >
      <div className='block md:hidden payment-modal-overlayer'></div>
      <div className='relative z-10 flex flex-col md:flex-row m-2 md:m-0 pt-10 md:pt-0'>
        <EventDetails
          {...{
            eventContactName,
            eventDate,
            eventImage: { url: eventImage },
            eventName,
            hostCreditEntity,
            itemNumber,
            paymentSourceType
          }}
        />
        <PaymentDetails
          {...{
            amountDue,
            amountPaid,
            duePriceBreakUpLabel,
            loginAndViewDetailsCTALabel,
            onClickLoginAndViewDetails,
            onClickPayAmount,
            orderTotal,
            payAmountCTALabel,
            paymentSourceType,
            paymentPriceBreakUpLabel
          }}
        />
      </div>
    </div>
  </div>
);

export default PublicPaymentModal;
