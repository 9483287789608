import Image from 'next/image';

import { Button, SectionHeader, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const getButtonPairOptions = ({ backward, forward, type }) => {
  const defaultOnClickAction = () => true;

  let defaultBackward = {
    show: true,
    name: 'Previous',
    className:
      'py-3 px-6 text-sm font-medium self-end rounded-lg bg-gray text-black',
    onClick: defaultOnClickAction
  };

  let defaultForward = {
    show: true,
    name: 'Next',
    className:
      'py-3 px-6 text-sm font-medium self-end rounded-lg bg-brand-gradient text-white',
    onClick: defaultOnClickAction
  };

  switch (type) {
    case 'viewEventDetail':
      defaultBackward = { ...defaultBackward, ...backward, name: 'Exit' };
      defaultForward = { ...defaultForward, ...forward, name: 'Edit' };
      break;
    case 'editEventDatail':
      defaultBackward = { ...defaultBackward, ...backward, name: 'Cancel' };
      defaultForward = { ...defaultForward, ...forward, name: 'Save' };
      break;
    case 'addNewCartAndViewPlannerBoard':
      defaultForward = {
        ...defaultForward,
        ...forward,
        name: 'View Planner Board'
      };
      defaultBackward = {
        ...defaultBackward,
        ...backward,
        name: 'Add New Cart'
      };
      break;
    default:
      break;
  }
  return {
    forward: { ...defaultForward },
    backward: { ...defaultBackward }
  };
};

const GenericButton = ({ name, onClick, className, show = true }) =>
  show && (
    <div>
      <Button
        className={className}
        onClick={onClick}
      >
        {name}
      </Button>
    </div>
  );

const EventCartDetailHeader = ({
  backward,
  forward,
  heading,
  headingStyle,
  isCopyToClipBoard,
  type
}) => {
  const options = getButtonPairOptions({
    backward,
    forward,
    type
  });

  const buttonOptions = [options.forward, options.backward];

  return (
    <div className='flex justify-between  my-6'>
      <div className='self-center flex gap-5'>
        <SectionHeader
          {...{
            heading,
            headingStyle
          }}
        />
        {isCopyToClipBoard && (
          <div className='border border-brand px-4 rounded-xl py-2 flex gap-4 cursor-pointer'>
            <Image
              src={`${staticMediaStoreBaseURL}/icons/copy-red.svg`}
              width={20}
              height={20}
              alt='copy'
            />
            <Text
              content='Copy to clipboard'
              className='text-sm font-medium text-brand'
            />
          </div>
        )}
      </div>
      <div className='flex gap-3'>
        {buttonOptions.map((option, id) => (
          <GenericButton
            key={id}
            {...{
              ...option
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default EventCartDetailHeader;
